<template>
	<div class="home">
		<HomeSectionOne />
		<HomeSectionTwo />
		<HomeSectionThree />
		<HomeSectionFour />
		<HomeSectionFive />
		<HomeSectionSix />
		<HomeSectionSeven />
	</div>
</template>
<script setup lang="ts">
import { handleAnimationObserver } from "../assets/js/observerAnimations/animationObserver";
import { fadeRule2 } from "@/assets/js/observerAnimations/rules";

function observations() {
	const fadeEntries: HTMLElement[] = Array.from(
		document.querySelectorAll(".fade2"),
	);
	handleAnimationObserver(fadeEntries, fadeRule2);
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss"></style>
