<template>
	<div class="sidebar-wrap">
		<div class="top">
			<div class="logo-wrap">
				<RouterLink to="/">
					<img
						class="logo"
						src="../../assets/images/icons/logo-main.svg"
						alt="Logo"
						@click="emit('toogleSidebar')"
					/>
				</RouterLink>
			</div>
			<img
				class="close"
				src="../../assets/images/icons/close.svg"
				alt="Close"
				@click="emit('toogleSidebar')"
			/>
		</div>
		<div class="links">
			<RouterLink to="/" @click="emit('toogleSidebar')">
				{{ transl("app.nav.home") }}
			</RouterLink>
			<RouterLink to="/services" @click="emit('toogleSidebar')">
				{{ transl("app.nav.services") }}
			</RouterLink>
			<!-- <RouterLink to="/blog" @click="emit('toogleSidebar')"> -->
			<a v-if="blogUrl" :href="blogLink" @click="emit('toogleSidebar')">{{
				transl("app.nav.blog")
			}}</a>
			<!-- </RouterLink> -->
		</div>
		<div class="icon-box">
			<a @click="changeLocale('en')"
				><img src="../../assets/images/icons/en.svg" alt="English" />
			</a>
			<a @click="changeLocale('sr')"
				><img src="../../assets/images/icons/srb.svg" alt="Serbian" />
			</a>
		</div>
		<div class="icon-box">
			<a
				href="https://www.instagram.com/ljubica_jovanovic_coach/"
				target="_blank"
				rel="noopener"
				><img src="../../assets/images/icons/ig.svg" alt="Instagram" />
			</a>
			<a
				href="https://www.facebook.com/profile.php?id=100005973528422"
				target="_blank"
				rel="noopener"
				><img src="../../assets/images/icons/fb.svg" alt="Facebook" />
			</a>
			<a
				href="https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F"
				target="_blank"
				rel="noopener"
				><img src="../../assets/images/icons/in.svg" alt="LinkedIn" />
			</a>
			<a
				href="https://www.youtube.com/@mentaltennis/featured"
				target="_blank"
				rel="noopener"
				><img src="../../assets/images/icons/yt.svg" alt="YouTube" />
			</a>
		</div>
	</div>
</template>
<script setup lang="ts">
import { i18n, transl } from "@/i18n";
import router from "@/router";
import { useDefaultStore } from "@/store";
const emit = defineEmits<{
	(e: "toogleSidebar"): void;
}>();

const changeLocale = (lang: string): void => {
	i18n.global.locale.value = lang;
	router.replace({ query: { l: lang } });
	// eslint-disable-next-line no-storage/no-browser-storage
	localStorage.setItem("language", lang);
};

const activeLang = computed(() => useDefaultStore().getBlogLanguage);
const blogUrl = computed(() => useDefaultStore().blogUrl);
const blogLink = computed(() => {
	const lang = activeLang.value;
	return `${blogUrl.value}/${lang}`;
});
</script>
<style scoped lang="scss">
.sidebar-wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 3;
	background-color: $background-color;
	padding: 0 $base-padding;
	display: flex;
	flex-direction: column;
	padding-bottom: 80px;

	.top {
		height: $nav-height;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo {
			height: 60px;
			cursor: pointer;
		}

		.close {
			width: 30px;
			height: 30px;
			cursor: pointer;
		}
	}

	.links {
		display: flex;
		flex-direction: column;
		text-align: center;
		font-size: 1.6rem;
		margin: auto 0;

		a {
			margin-bottom: 1rem;
		}
	}

	.icon-box {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin-top: 50px;

		img {
			width: 30px;
			height: 30px;
		}
	}
}
</style>
