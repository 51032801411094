<template>
	<div class="nav-wrap-outer" :class="{ 'solid-nav': scrolled >= 3 }">
		<div class="nav-wrap">
			<div class="left">
				<RouterLink to="/">
					<img
						src="../../assets/images/icons/logo-main.svg"
						alt=""
						@click="scrollToTop"
					/>
				</RouterLink>
			</div>
			<div class="middle">
				<RouterLink class="nav-home" to="/">
					{{ transl("app.nav.home") }}
				</RouterLink>
				<RouterLink class="nav-services" to="/services">
					{{ transl("app.nav.services") }}
				</RouterLink>
				<a v-if="blogLink" :href="blogLink" class="nav-blog">{{
					transl("app.nav.blog")
				}}</a>
				<div ref="navIndicatorRef" class="active-indicator"></div>
			</div>
			<div class="right">
				<a
					href="https://www.instagram.com/ljubica_jovanovic_coach/"
					target="_blank"
					><img src="../../assets/images/icons/ig.svg" alt="Instagram" />
				</a>
				<a
					href="https://www.facebook.com/profile.php?id=100005973528422"
					target="_blank"
				>
					<img src="../../assets/images/icons/fb.svg" alt="Facebook" />
				</a>
				<a
					href="https://rs.linkedin.com/in/ljubica-jovanovic-b8a445124?trk=people-guest_people_search-card&original_referer=https%3A%2F%2Fwww.linkedin.com%2F"
					target="_blank"
					><img src="../../assets/images/icons/in.svg" alt="Linkedin" />
				</a>
				<a
					href="https://www.youtube.com/@mentaltennis/featured"
					target="_blank"
				>
					<img src="../../assets/images/icons/yt.svg" alt="Youtube" />
				</a>
				<a @click="changeLocale('en')">
					<img src="../../assets/images/icons/en.svg" alt="English" />
				</a>
				<a @click="changeLocale('sr')">
					<img src="../../assets/images/icons/srb.svg" alt="Serbian" />
				</a>
			</div>
			<div class="burger-menu" @click="emit('toogleSidebar')">
				<img src="../../assets/images/icons/burger.svg" alt="burger menu" />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { i18n, transl } from "@/i18n";
import { scrollToTop } from "../../assets/js/helpers";
import { type RouteRecordName, useRoute } from "vue-router";
import router from "@/router";
import { useDefaultStore } from "@/store";

const route = useRoute();

let scrolled: Ref<number> = ref(0);
let navIndicatorRef: Ref<HTMLElement | null> = ref(null);

const changeLocale = (lang: string): void => {
	i18n.global.locale.value = lang;
	router.replace({ query: { l: lang } });
	// eslint-disable-next-line no-storage/no-browser-storage
	localStorage.setItem("language", lang);
};

const emit = defineEmits<{
	(e: "toogleSidebar"): void;
}>();

function getDocHeight() {
	let D = document;
	return Math.max(
		D.body.scrollHeight,
		D.documentElement.scrollHeight,
		D.body.offsetHeight,
		D.documentElement.offsetHeight,
		D.body.clientHeight,
		D.documentElement.clientHeight,
	);
}

function amountscrolled() {
	let winheight =
		window.innerHeight ||
		(document.documentElement || document.body).clientHeight;
	let docheight = getDocHeight();
	let scrollTop =
		window.scrollY ||
		(document.documentElement || document.body.parentNode || document.body)
			.scrollTop;
	let trackLength = docheight - winheight;
	let pctScrolled = Math.floor((scrollTop / trackLength) * 100); // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
	return pctScrolled;
}
const activeLang = computed(() => useDefaultStore().getBlogLanguage);
const blogUrl = computed(() => useDefaultStore().blogUrl);
const blogLink = computed(() => {
	const lang = activeLang.value;
	return `${blogUrl.value}/${lang}`;
});

function updateNavIndicator(route: RouteRecordName | null | undefined) {
	let homeNavEl = document.querySelector(".nav-home") as HTMLElement;
	let servicesNavEl = document.querySelector(".nav-services") as HTMLElement;

	console.log(
		homeNavEl.offsetLeft,
		// servicesNavEl.getBoundingClientRect().width
	);

	if (route === "Home" && navIndicatorRef.value) {
		const navElWidth = homeNavEl.getBoundingClientRect().width;
		const navElOffseLeft = homeNavEl.offsetLeft;
		navIndicatorRef.value.style.width = `${navElWidth}px`;
		navIndicatorRef.value.style.left = `${navElOffseLeft}px`;
	}

	if (route === "Services" && navIndicatorRef.value) {
		const navElWidth = servicesNavEl.getBoundingClientRect().width;
		const navElOffseLeft = servicesNavEl.offsetLeft;
		navIndicatorRef.value.style.width = `${navElWidth}px`;
		navIndicatorRef.value.style.left = `${navElOffseLeft}px`;
	}
}

watch(
	() => [route.name],
	() => updateNavIndicator(route.name),
);

onMounted(() => {
	updateNavIndicator(route.name);
});

window.addEventListener(
	"scroll",
	function () {
		scrolled.value = amountscrolled();
	},
	false,
);
</script>
<style scoped lang="scss">
.solid-nav {
	background-color: $header-footer-color;
}

.nav-wrap-outer {
	transition: background-color 0.5s ease-in-out;
	padding: 0 $base-padding;
	position: fixed;
	width: 100%;
	z-index: 2;

	.nav-wrap {
		margin: 0 auto;
		height: $nav-height;
		width: 100%;
		max-width: $content-width;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left img {
			height: 60px;
			cursor: pointer;
		}

		.middle {
			a {
				font-size: 1.2rem;
				margin: 1rem;
				padding-bottom: 5px;
				font-weight: 500;
				transition: all 1s linear;
				cursor: pointer;
			}

			.active-indicator {
				position: absolute;
				width: 15px;
				height: 5px;
				bottom: -5px;
				left: 0;
				background-color: $color-yellow;
				transition: left 0.3s ease-in;
			}
		}

		.right {
			a {
				cursor: pointer;

				img {
					transition: all 0.2s ease-in-out;

					&:hover {
						transform: scale(1.1);
						opacity: 0.9;
					}
				}
			}

			/* stylelint-disable-next-line no-descending-specificity */
			img {
				margin-left: 25px;
			}
		}

		.burger-menu {
			display: none;
			cursor: pointer;

			/* stylelint-disable-next-line no-descending-specificity */
			img {
				width: 40px;
				height: 40px;
			}
		}
	}
}

@media only screen and (width <= 800px) {
	.middle,
	.right {
		display: none;
	}

	.burger-menu {
		display: block !important;
	}
}
</style>
