<template>
	<div class="section-outer">
		<div class="section-inner">
			<img
				ref="dotFiveRef"
				class="shadow-dot sd5"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<h3>{{ transl("app.home.section7.title") }}</h3>
			<div
				class="cards-container"
				:class="{ 'single-card-container': isSingleCardLayout }"
			>
				<div v-if="isSingleCardLayout" class="previouvs-mobile">
					<img
						src="../../assets/images/icons/arrow-left.svg"
						alt=""
						@click="previousCard"
					/>
				</div>
				<div
					v-for="(card, index) in currentCards"
					:key="index"
					class="card default-border"
					:class="[{ 'blue-card': index === 1 }]"
				>
					<h4>{{ card.name }}</h4>
					<p class="sub-title">{{ card.title }}</p>
					<p class="rec-content">
						{{ card.content }}
					</p>
				</div>
				<div v-if="isSingleCardLayout" class="next-mobile">
					<img
						src="../../assets/images/icons/arrow-right.svg"
						alt=""
						@click="nextCard"
					/>
				</div>
			</div>

			<div v-if="!isSingleCardLayout" class="s7-arrows">
				<img
					class="s7-arrow"
					src="../../assets/images/icons/arrow-left.svg"
					alt=""
					@click="previousCard"
				/>
				<img
					class="s7-arrow"
					src="../../assets/images/icons/arrow-right.svg"
					alt=""
					@click="nextCard"
				/>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";

type Card = {
	name: string;
	title: string;
	content: string;
};
let isSingleCardLayout: Ref<boolean> = ref(false);
let focusedCardIndex: Ref<number> = ref(1);
let dotFiveRef: Ref<HTMLElement | null> = ref(null);

let rawCards = computed<Card[]>(() => {
	let arr: Card[] = [];
	for (let i = 1; i <= 6; i++) {
		let card: Card = {
			name: transl(`app.home.section7.recension${i}.name`),
			title: transl(`app.home.section7.recension${i}.title`),
			content: transl(`app.home.section7.recension${i}.content`),
		};
		arr.push(card);
	}
	return arr;
});

let currentCards = computed<Card[]>(() => {
	let previousCard = rawCards.value[focusedCardIndex.value - 1];
	let currentCard = rawCards.value[focusedCardIndex.value];
	let nextCard = rawCards.value[focusedCardIndex.value + 1];

	if (isSingleCardLayout.value) {
		return [currentCard];
	}

	let cardsLastIndex = rawCards.value.length - 1;

	if (focusedCardIndex.value == 0) {
		previousCard = rawCards.value[cardsLastIndex];
	}
	if (cardsLastIndex === focusedCardIndex.value) {
		nextCard = rawCards.value[0];
	}

	return [previousCard, currentCard, nextCard];
});

function previousCard() {
	focusedCardIndex.value === 0
		? (focusedCardIndex.value = rawCards.value.length - 1)
		: (focusedCardIndex.value = focusedCardIndex.value - 1);
}
function nextCard() {
	focusedCardIndex.value === rawCards.value.length - 1
		? (focusedCardIndex.value = 0)
		: (focusedCardIndex.value = focusedCardIndex.value + 1);
}
function handleIsSingleCard() {
	isSingleCardLayout.value = window.innerWidth < 1350;
}

function observations() {
	handleAnimationObserver(getObserverEntries(dotFiveRef.value), fadeRule);
}

onMounted(() => {
	handleIsSingleCard();
	window.addEventListener("resize", handleIsSingleCard);
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		.single-card-container {
			margin-bottom: 60px;

			.card {
				max-width: 400px !important;
				width: 100% !important;
				height: auto !important;
			}
		}

		.cards-container {
			display: flex;
			justify-content: center;

			.blue-card {
				background-color: #5475a0;
			}

			.previouvs-mobile,
			.next-mobile {
				display: flex;
				align-items: center;

				img {
					width: 25px;
					height: 25px;
					transition: all 0.2s ease-out;

					&:hover {
						transform: scale(1.2);
					}
				}
			}

			.card {
				width: 32%;
				margin: 5px;
				height: 600px;
				padding: 21px;

				h4 {
					font-size: 1.7rem;
					margin-bottom: 10px;
					font-weight: 600;
					text-align: center;
				}

				.sub-title {
					margin-bottom: 30px;
					font-weight: 500;
					text-align: center;
				}

				p {
					text-align: left;
					font-weight: 500;
				}

				.rec-content {
					font-style: italic;
				}
			}
		}

		.s7-arrows {
			display: flex;
			justify-content: center;
			margin: 130px auto 150px;

			.s7-arrow {
				margin: 0 7px;
				cursor: pointer;
				transition: all 0.2s ease-out;

				&:hover {
					transition: all 0.2s ease-out;
					transform: scale(1.2);
				}
			}
		}
	}
}
</style>
