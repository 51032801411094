<template>
	<div class="section-outer">
		<div class="section-inner">
			<img
				ref="dotOneRef"
				class="shadow-dot service-sd1"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<div class="container">
				<div class="circle-wrap odd clickable" @click="tennisMentalTraining">
					<div class="image-wrap">
						<img src="../../assets/images/photos/services/brain.png" alt="" />
					</div>
					<h5>{{ transl("app.services.section2.text1") }}</h5>
				</div>
				<div class="circle-wrap even clickable" @click="tennisBussines">
					<div class="image-wrap">
						<img
							src="../../assets/images/photos/services/playground.png"
							alt=""
						/>
					</div>
					<h5>{{ transl("app.services.section2.text2") }}</h5>
				</div>
				<div class="circle-wrap odd clickable" @click="tennisWorkshop">
					<div class="image-wrap">
						<img src="../../assets/images/photos/services/hat.png" alt="" />
					</div>
					<h5>{{ transl("app.services.section2.text3") }}</h5>
				</div>
				<div class="circle-wrap even clickable" @click="tennisParents">
					<div class="image-wrap">
						<img src="../../assets/images/photos/services/people.png" alt="" />
					</div>
					<h5>{{ transl("app.services.section2.text4") }}</h5>
				</div>
				<div class="circle-wrap odd">
					<div class="image-wrap">
						<img src="../../assets/images/photos/services/recket.png" alt="" />
					</div>
					<h5>{{ transl("app.services.section2.text5") }}</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";
// import { defineEmits } from "vue";
// const emit = defineEmits(["scrollToTraining"]);

let dotOneRef: Ref<HTMLElement | null> = ref(null);

function observations() {
	handleAnimationObserver(getObserverEntries(dotOneRef.value), fadeRule);
}

function tennisMentalTraining() {
	const element = document.querySelector("#tenis-one-to-one") as HTMLElement;
	const headerHeight = 100; // Visina vašeg headera u pikselima
	if (element) {
		console.log(element);
		console.log(element.offsetTop);

		const elementPosition = element.offsetTop - headerHeight;
		window.scrollTo({
			top: elementPosition,
			behavior: "smooth",
		});
	}
}

// Ostale funkcije za skrolanje imaju sličan obrazac.

function tennisBussines() {
	const element = document.querySelector("#bussines-one-to-one") as HTMLElement;
	const headerHeight = 100; // Visina vašeg headera u pikselima
	if (element) {
		const elementPosition = element.offsetTop - headerHeight;
		window.scrollTo({
			top: elementPosition,
			behavior: "smooth",
		});
	}
}

function tennisWorkshop() {
	const element = document.querySelector("#workshop-be-strong") as HTMLElement;
	const headerHeight = 100; // Visina vašeg headera u pikselima
	if (element) {
		const elementPosition = element.offsetTop - headerHeight;
		window.scrollTo({
			top: elementPosition,
			behavior: "smooth",
		});
	}
}

function tennisParents() {
	const element = document.querySelector(
		"#center-for-parents-and-children",
	) as HTMLElement;
	const headerHeight = 100; // Visina vašeg headera u pikselima
	if (element) {
		const elementPosition = element.offsetTop - headerHeight;
		window.scrollTo({
			top: elementPosition,
			behavior: "smooth",
		});
	}
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		.container {
			margin: 160px 0;
			display: flex;
			justify-content: space-evenly;
			flex-wrap: wrap;

			.odd {
				justify-content: flex-end;
			}

			.even {
				justify-content: flex-start;
			}

			.circle-wrap {
				text-align: center;
				max-width: 220px;
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 350px;
				min-width: 274px;
				gap: 10px;
				cursor: pointer;

				.image-wrap {
					box-shadow: 4px 5px 18px 0 #ffffff0d;
					border-radius: 100%;
					min-width: 160px;
					min-height: 160px;
					width: 160px;
					height: 160px;
					background-color: #091628;
					display: grid;
					place-items: center;
				}

				.image-wrap:hover {
					box-shadow: 14px 15px 28px 0 #ffffff0d;
				}

				h5 {
					font-weight: 700;
					font-size: 26px;
					height: 80px;
					margin-top: 5px;
				}
			}
		}
	}
}

@media only screen and (width <= 1200px) {
	.section-outer {
		.section-inner {
			.container {
				max-width: 500px;
				margin: 60px auto;
				justify-content: center;
				gap: 30px;

				.odd {
					justify-content: flex-start;
				}

				.even {
					justify-content: flex-start;
				}

				.circle-wrap {
					max-width: 45%;
					min-width: 150px;
					width: 225px;
					height: auto;
					font-size: 20px;

					h5 {
						height: auto;
						font-size: 22px !important;
					}

					.image-wrap {
						min-width: 100px;
						min-height: 100px;
						width: 100px;
						height: 100px;

						img {
							max-width: 40px;
							max-height: 40px;
						}
					}
				}
			}
		}
	}
}
</style>
