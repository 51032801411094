export type ObserverRule = {
	hidden: object;
	visible: object;
	once?: boolean;
};

export const fadeRule: ObserverRule = {
	hidden: {
		opacity: "0",
		transitionDuration: ".5s",
	},
	visible: {
		opacity: "1",
	},
};

export const fadeRule2: ObserverRule = {
	hidden: {
		opacity: "0",
		transitionDuration: ".3s",
	},
	visible: {
		opacity: "1",
	},
};

export const slideLeftInOut: ObserverRule = {
	hidden: {
		opacity: "0",
		transitionDuration: ".5s",
	},
	visible: {
		opacity: "1",
	},
};
