<template>
	<div class="section-outer">
		<div class="section-inner">
			<img
				ref="dotRef"
				class="shadow-dot service-sd3"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<div class="pricing-box" :class="[{ foreign: activeLang === 'en' }]">
				<div class="session-pricing">
					<p>{{ transl("app.services.section4.left.title") }}</p>
					<div class="session-box a">
						<span>{{ transl("app.services.section4.left.row1.zone") }}</span>
						<span>{{ transl("app.services.section4.left.row1.price") }}</span>
					</div>
					<div class="session-box b">
						<span>{{ transl("app.services.section4.left.row2.zone") }}</span>
						<span>{{ transl("app.services.section4.left.row2.price") }}</span>
					</div>
					<div class="session-box c">
						<span class="label">{{
							transl("app.services.section4.left.row3.zone")
						}}</span>
						<span>{{ transl("app.services.section4.left.row3.price") }}</span>
					</div>
				</div>

				<div class="session-pricing">
					<p>{{ transl("app.services.section4.right.title") }}</p>
					<div class="session-box a">
						<span>{{ transl("app.services.section4.right.row1.zone") }}</span>
						<span>{{ transl("app.services.section4.right.row1.price") }}</span>
					</div>
					<div class="session-box b">
						<span>{{ transl("app.services.section4.right.row2.zone") }}</span>
						<span>{{ transl("app.services.section4.right.row2.price") }}</span>
					</div>
					<div class="session-box c">
						<span class="label">{{
							transl("app.services.section4.right.row3.zone")
						}}</span>
						<span>{{ transl("app.services.section4.right.row3.price") }}</span>
					</div>
				</div>
			</div>

			<div class="img-wrap mt20">
				<img
					class="s3s-img"
					src="../../assets/images/photos/services/section3.png"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";
import { useDefaultStore } from "@/store";

let dotRef: Ref<HTMLElement | null> = ref(null);

const activeLang = computed(() => useDefaultStore().getBlogLanguage);
function observations() {
	handleAnimationObserver(getObserverEntries(dotRef.value), fadeRule);
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		.pricing-box {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;

			&.foreign {
				.a,
				.b {
					display: none;
				}

				.c {
					.label {
						display: none;
					}

					span:not(.label) {
						margin: auto;
					}
				}
			}
		}

		.img-wrap {
			display: none;
		}
	}
}

@media only screen and (width <= 1100px) {
	.section-outer {
		.section-inner {
			.img-wrap {
				display: grid;
				place-items: center;

				img {
					max-width: 100%;
				}
			}
		}
	}
}
</style>
