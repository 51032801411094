import api from "@/api";
import { getLocalStorageReac } from "@/assets/js/helpers";
import router from "@/router";
import { defineStore } from "pinia";

type TConfig = {
	"blog-link": string;
};

export const useDefaultStore = defineStore("main", () => {
	const getBlogLanguage = computed(() => {
		const langStorage = getLocalStorageReac("language", false, "sr")?.value;
		const langFromRoute = router.currentRoute.value.query.l;
		const lang = langFromRoute || langStorage || "sr";
		return lang;
	});

	const appConfig = ref<TConfig>({} as TConfig);
	const blogUrl = computed<string>(() => {
		let url = appConfig.value["blog-link"] || "";
		if (url && !/^https?:\/\//i.test(url)) {
			url = "http://" + url;
		}
		return url;
	});

	async function getConfig() {
		try {
			const res = await api.getConfig({});
			appConfig.value = res.data as TConfig;
		} catch (err: any) {
			console.warn(err.message);
		}
	}

	return {
		getBlogLanguage,
		appConfig,
		blogUrl,
		getConfig,
	};
});
