<template>
	<div id="bussines-one-to-one" class="section-outer">
		<div class="section-inner">
			<img
				ref="dotRef"
				class="shadow-dot service-sd4"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<h3>{{ transl("app.services.section5.title") }}</h3>
			<p>{{ transl("app.services.section5.topParagraph") }}</p>

			<div class="middle">
				<div class="img-wrap">
					<img src="../../assets/images/photos/services/section5.png" alt="" />
				</div>
				<div class="content">
					<p>{{ transl("app.services.section5.middle.paragraph1") }}</p>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph2") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph3") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph4") }}
						</p>
					</div>
					<p class="mt20">
						{{ transl("app.services.section5.middle.paragraph5") }}
					</p>
					<p>
						{{ transl("app.services.section5.middle.paragraph6") }}
					</p>
					<p>
						{{ transl("app.services.section5.middle.paragraph7") }}
					</p>
					<p>
						{{ transl("app.services.section5.middle.paragraph8") }}
					</p>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph9") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph10") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph11") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph12") }}
						</p>
					</div>

					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph13") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph14") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section5.middle.paragraph15") }}
						</p>
					</div>
				</div>
			</div>
			<p class="mt20">
				{{ transl("app.services.section5.bottom.paragraph1") }}
			</p>
			<p>{{ transl("app.services.section5.bottom.paragraph2") }}</p>
			<p>{{ transl("app.services.section5.bottom.paragraph3") }}</p>
			<h6>{{ transl("app.services.section5.pricing.title") }}</h6>
			<div class="pricing-box" :class="[{ foreign: activeLang === 'en' }]">
				<div class="session-pricing a">
					<p>{{ transl("app.services.section5.pricing.left.title") }}</p>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.left.row1.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.left.row1.price")
						}}</span>
					</div>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.left.row2.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.left.row2.price")
						}}</span>
					</div>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.left.row3.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.left.row3.price")
						}}</span>
					</div>
				</div>
				<div class="session-pricing b">
					<p>{{ transl("app.services.section5.pricing.middle.title") }}</p>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.middle.row1.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.middle.row1.price")
						}}</span>
					</div>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.middle.row2.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.middle.row2.price")
						}}</span>
					</div>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.middle.row3.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.middle.row3.price")
						}}</span>
					</div>
				</div>
				<div class="session-pricing c">
					<p>{{ transl("app.services.section5.pricing.right.title") }}</p>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.right.row1.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.right.row1.price")
						}}</span>
					</div>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.right.row2.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.right.row2.price")
						}}</span>
					</div>
					<div class="session-box">
						<span>{{
							transl("app.services.section5.pricing.right.row3.zone")
						}}</span>
						<span>{{
							transl("app.services.section5.pricing.right.row3.price")
						}}</span>
					</div>
				</div>
			</div>
			<div class="img-wrap-mobile mt20">
				<img src="../../assets/images/photos/services/section5.png" alt="" />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";
import { useDefaultStore } from "@/store";

let dotRef: Ref<HTMLElement | null> = ref(null);

const activeLang = computed(() => useDefaultStore().getBlogLanguage);
function observations() {
	handleAnimationObserver(getObserverEntries(dotRef.value), fadeRule);
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		h3 {
			margin: 150px 0 50px;
		}

		p {
			margin-bottom: 20px;
		}

		h6 {
			font-size: 30px;
			margin-top: 50px;
			text-align: center;
		}

		.middle {
			display: flex;

			.img-wrap {
				display: grid;
				place-items: center;
			}

			.content {
				margin-left: 100px;

				.grp {
					display: flex;
					margin-bottom: 5px;

					p {
						margin-bottom: 0;
					}

					img {
						margin: 3px 10px auto 0;
					}
				}
			}
		}

		.pricing-box {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-evenly;

			&.foreign {
				.a,
				.b {
					display: none;
				}

				.c {
					> p {
						visibility: collapse;
					}
				}
			}
		}

		.img-wrap-mobile {
			display: none;
		}
	}
}

@media only screen and (width <= 1000px) {
	.section-outer {
		.section-inner {
			h3 {
				margin: 50px 0 30px;
				font-size: 32px;
			}

			h6 {
				font-size: 20px;
				margin-top: 50px;
				text-align: center;
			}

			.middle {
				.img-wrap {
					display: none;
				}

				.content {
					margin-left: 0;
				}
			}

			.img-wrap-mobile {
				display: grid;
				place-items: center;

				img {
					max-width: 100%;
				}
			}
		}
	}
}
</style>
