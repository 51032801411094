<template>
	<div class="section-outer">
		<div class="section-inner">
			<h3 class="s6-title">{{ transl("app.home.section6.title") }}</h3>
			<div class="s6-content">
				<div class="qa-container">
					<div
						v-for="(qa, index) in questions"
						ref="questionRefs"
						:key="index"
						class="qa"
						:class="{ 'qa-expanded': expandedQuestion === index }"
						@click="toogleExtend(index)"
					>
						<div class="icon-wrap">
							<img
								class="plus"
								src="../../assets/images/icons/plus.svg"
								alt=""
							/>
							<img
								class="minus"
								src="../../assets/images/icons/minus.svg"
								alt=""
							/>
						</div>
						<div class="text">
							<div class="question-text">
								<p>{{ qa.question }}</p>
							</div>
							<div class="answer-text">
								<p>{{ qa.answer }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="s6-img">
					<img src="../../assets/images/photos/home/section6.png" alt="" />
				</div>
			</div>
			<!-- {{ computedQuestions }}
      <br />
      {{ questions }} -->
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
type QA = {
	question: string;
	answer: string;
};

let expandedQuestion: Ref<number | null> = ref(null);
const questionRefs = ref<(HTMLElement | null)[]>([]);

function toogleExtend(index: number) {
	if (expandedQuestion.value === index) {
		expandedQuestion.value = null;
	} else {
		expandedQuestion.value = index;
		nextTick(() => {
			const element = questionRefs.value[index];
			if (element) {
				const headerHeight = 100; // Visina headera
				const elementPosition =
					element.getBoundingClientRect().top +
					window.pageYOffset -
					headerHeight;
				window.scrollTo({ top: elementPosition, behavior: "smooth" });
			}
		});
	}
}

let questions = computed(() => {
	let arr: QA[] = [];
	for (let i = 1; i <= 5; i++) {
		arr.push({
			question: transl(`app.home.section6.question${i}`),
			answer: transl(`app.home.section6.answer${i}`),
		});
	}
	return arr;
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		margin: 120px auto;

		.s6-content {
			display: flex;
			justify-content: space-between;

			.qa-container {
				padding: 0 40px 40px 0;
				min-width: 400px;
				max-width: 900px;

				.qa {
					display: flex;
					font-size: 1.7rem;
					padding: 5px 30px;
					border-radius: 11px;
					cursor: pointer;
					justify-content: flex-start;
					transition: all 0.5s ease-in-out;

					.icon-wrap {
						min-width: 80px;
						display: flex;

						.plus {
							margin: 7px auto auto;
							width: 25px;
						}

						.minus {
							margin: 5px auto auto;
							width: 35px;
							display: none;
						}
					}

					.text {
						.question-text {
							font-weight: 700;
						}

						.answer-text {
							display: none;
						}
					}
				}

				.qa-expanded {
					background-color: $color-sky-blue;

					.icon-wrap {
						.minus {
							display: flex;
						}

						.plus {
							display: none;
						}
					}

					.text {
						.answer-text {
							display: block;
						}
					}
				}
			}
		}
	}
}

@media only screen and (width <= 1400px) {
	.section-outer {
		.section-inner {
			.s6-content {
				flex-direction: column;
				max-width: 100%;

				.s6-img {
					max-width: 80%;
					margin: 0 auto;

					img {
						max-width: 100%;
					}
				}
			}
		}
	}
}

@media only screen and (width <= 850px) {
	.section-outer {
		.section-inner {
			margin-top: 0;

			.s6-content {
				.s6-img {
					max-width: 100%;
				}

				.qa-container {
					min-width: unset;

					.qa {
						padding: 10px;

						.icon-wrap {
							max-width: 30px;
							min-width: unset;
							margin-right: 12px;

							.plus {
								margin: 7px auto auto;
								width: 15px;
							}

							.minus {
								margin: 5px auto auto;
								width: 20px;
							}
						}

						.text {
							.question-text {
								font-size: 18px;
							}

							.answer-text {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
