<template>
	<div class="section-outer">
		<div class="section-inner">
			<img
				ref="dotOneRef"
				class="shadow-dot sd1"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<img
				ref="dotTwoRef"
				class="shadow-dot sd2"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<div class="sec2-grp">
				<div class="text">
					<h2>{{ transl("app.home.section2.group1.title") }}</h2>
					<p class="fade2">{{ transl("app.home.section2.group1.subTitle") }}</p>
					<div class="content-wrap fade2">
						<div class="content s2-slide-left">
							<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
							<span>{{ transl("app.home.section2.group1.content1") }}</span>
						</div>
						<div class="content s2-slide-left fade2">
							<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
							<span>{{ transl("app.home.section2.group1.content2") }}</span>
						</div>
					</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/photos/home/section2.1.png" alt="" />
				</div>
			</div>
			<div class="sec2-grp reverse">
				<div class="img-wrap">
					<img src="../../assets/images/photos/home/section2.2.png" alt="" />
				</div>
				<div class="text">
					<h2>{{ transl("app.home.section2.group2.title") }}</h2>
					<p>{{ transl("app.home.section2.group2.subTitle") }}</p>
					<div class="content-wrap">
						<div class="content fade2">
							<img src="../../assets/images/icons/suitcase.svg" alt="" />
							<span>{{ transl("app.home.section2.group2.content1") }}</span>
						</div>
						<div class="content fade2">
							<img src="../../assets/images/icons/suitcase.svg" alt="" />
							<span>{{ transl("app.home.section2.group2.content2") }}</span>
						</div>
						<div class="content fade2">
							<img src="../../assets/images/icons/suitcase.svg" alt="" />
							<span>{{ transl("app.home.section2.group2.content3") }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="sec2-grp">
				<div class="text">
					<h2>{{ transl("app.home.section2.group3.title") }}</h2>
					<p>{{ transl("app.home.section2.group3.subTitle") }}</p>
					<div class="content-wrap">
						<div class="content fade2">
							<img src="../../assets/images/icons/user-icon.svg" alt="" />
							<span>{{ transl("app.home.section2.group3.content1") }}</span>
						</div>
						<div class="content fade2">
							<img src="../../assets/images/icons/user-icon.svg" alt="" />
							<span>{{ transl("app.home.section2.group3.content2") }}</span>
						</div>
						<div class="content fade2">
							<img src="../../assets/images/icons/user-icon.svg" alt="" />
							<span>{{ transl("app.home.section2.group3.content3") }}</span>
						</div>
					</div>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/photos/home/section2.3.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";

let dotOneRef: Ref<HTMLElement | null> = ref(null);
let dotTwoRef: Ref<HTMLElement | null> = ref(null);

function observations() {
	handleAnimationObserver(
		getObserverEntries(dotOneRef.value, dotTwoRef.value),
		fadeRule,
	);
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		padding: 150px 0 0;

		.sec2-grp {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 70px 0;

			.img-wrap {
				max-width: 100%;

				img {
					max-width: 100%;
				}

				.reverse {
					flex-direction: column-reverse;
				}
			}

			.text {
				margin: 70px 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-width: 400px;
				padding-right: 20px;

				h2 {
					font-size: 2.2em;
					font-weight: 600;
				}

				p {
					font-size: 1.5rem;
					margin-bottom: 40px;
					font-weight: 700;
				}

				.content {
					margin-bottom: 10px;
					display: flex;
					align-items: center;

					span {
						font-size: 1.3rem;
						font-weight: 400;
						top: -2px;
					}

					img {
						margin: 4px 20px auto 0;
						height: 100%;
					}
				}
			}
		}

		/* stylelint-disable-next-line no-descending-specificity */
		.reverse {
			.img-wrap {
				margin: auto 45px auto 0;
			}
		}
	}
}

@media only screen and (width <= 1050px) {
	.section-outer {
		.section-inner {
			padding: 0;

			.reverse {
				flex-direction: column-reverse !important;
			}

			.sec2-grp {
				flex-direction: column;
				margin: 20px 0;

				.text {
					min-width: unset;
					margin: 20px 0;

					h2 {
						font-size: 1.5rem;
					}
				}

				.img-wrap {
					margin: 0 auto;
					max-width: 90%;
				}
			}
		}
	}
}
</style>
