<template>
	<div class="section-outer">
		<img
			ref="dotFourRef"
			class="shadow-dot sd4"
			src="../../assets/images/dots/desktop/dot1.svg"
			alt=""
		/>
		<div class="section-inner">
			<h2 class="s4-title">{{ transl("app.home.section4.title") }}</h2>
			<p class="s4-sub-title">{{ transl("app.home.section4.subTitle") }}</p>
			<div class="top">
				<div class="img-wrap">
					<img src="../../assets/images/photos/home/section4.1.png" alt="" />
				</div>
				<div class="top-text">
					<p>{{ transl("app.home.section4.top.paragraph1") }}</p>
					<p>{{ transl("app.home.section4.top.paragraph2") }}</p>
					<p>{{ transl("app.home.section4.top.paragraph3") }}</p>
					<p>{{ transl("app.home.section4.top.paragraph4") }}</p>
				</div>
			</div>
			<p>{{ transl("app.home.section4.middleParagraph") }}</p>

			<div class="bot">
				<div class="bot-text">
					<p>{{ transl("app.home.section4.bot.paragraph1") }}</p>
					<p>{{ transl("app.home.section4.bot.paragraph2") }}</p>
					<p>{{ transl("app.home.section4.bot.paragraph3") }}</p>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/photos/home/section4.2.png" alt="" />
				</div>
			</div>
			<img
				ref="ballRef"
				class="s4-ball"
				src="../../assets/images/photos/home/s4-ball.svg"
				alt=""
			/>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";

let dotFourRef: Ref<HTMLElement | null> = ref(null);
let ballRef: Ref<HTMLElement | null> = ref(null);

function getPercentageHeightOfViewport() {
	let elRecY = ballRef.value?.getBoundingClientRect().y as number;
	let percentage;
	if (elRecY >= 0 && elRecY < window.innerHeight)
		percentage = Math.round((100 / window.innerHeight) * elRecY);
	else if (elRecY > 0) percentage = 0;
	else if (elRecY > window.innerHeight) percentage = 0;
	if (percentage && ballRef.value)
		ballRef.value.style.transform = `translateX(-${percentage * 2}px)`;
}

addEventListener("scroll", getPercentageHeightOfViewport);

function observations() {
	handleAnimationObserver(getObserverEntries(dotFourRef.value), fadeRule);
}

onMounted(() => {
	observations();
});
</script>

<style scoped lang="scss">
.section-outer {
	.section-inner {
		padding: 100px 0 0;

		.s4-ball {
			position: absolute;
			right: -150px;
			bottom: 0;
			max-width: 180px;
			min-width: 100px;
			width: 10vw;
		}

		.s4-title {
			font-size: 3rem;
			margin-bottom: 0;
			text-align: center;
		}

		.s4-sub-title {
			color: $color-yellow;
			font-size: 1.8rem;
			font-weight: 400;
			margin-bottom: 30px;
			font-style: italic;
			text-align: center;
		}

		p {
			margin-bottom: 25px;
			font-size: 1.3rem;
			font-weight: 500;
		}

		.top {
			margin-top: 70px;
			display: flex;

			img {
				max-height: 350px;
				margin: 0 80px 50px 0;
			}
		}

		.bot {
			display: flex;

			img {
				max-height: 350px;
				margin-left: 50px;
			}
		}
	}
}

@media only screen and (width <= 1700px) {
	.section-outer {
		.section-inner {
			.s4-ball {
				right: 0;
				bottom: 0;
			}
		}
	}
}

@media only screen and (width <= 1000px) {
	.section-outer {
		.section-inner {
			padding: 50px 0 0;

			.top {
				flex-direction: column;
				margin-top: 0;

				.img-wrap {
					display: flex;
					justify-content: center;

					img {
						margin: 0 auto 30px;
					}
				}
			}

			.bot {
				flex-direction: column-reverse;

				.img-wrap {
					display: flex;
					justify-content: center;

					img {
						margin: 30px auto;
					}
				}
			}
		}
	}
}

@media only screen and (width <= 600px) {
	.section-outer {
		.section-inner {
			padding-bottom: 20px;

			.s4-title {
				font-size: 24px;
			}

			.s4-sub-title {
				font-size: 20px;
			}

			p {
				font-size: 18px;
			}
		}
	}
}
</style>
