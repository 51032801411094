import { type ObserverRule } from "./rules";

export const defaultOptions = {
	root: null,
	rootMargin: "-70px 0px -60px",
	threshold: 0.4,
};

export function handleAnimationObserver(
	entries: HTMLElement[],
	rules: ObserverRule,
	options: any = defaultOptions,
): void {
	//SET INITIAL STATE TO ALL ELEMENTS
	entries.forEach((entry: HTMLElement) => {
		updateElement(entry, rules.hidden);
	});
	//TOGGLING STATES
	const callback = (entries: any[], observer: any) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				updateElement(entry, rules.visible);
				if (rules.once) observer.unobserve(entry.target);
				// console.log("Enters");
			} else {
				updateElement(entry, rules.hidden);
				// console.log("Leaves");
			}
		});
	};

	const observer = new IntersectionObserver(callback, options);
	entries.forEach((entry) => {
		observer.observe(entry);
	});
}

export function updateElement(
	el: HTMLElement | IntersectionObserverEntry,
	rules: any,
) {
	if (el instanceof IntersectionObserverEntry) {
		Object.keys(rules).forEach((key) => {
			// @ts-ignore
			el.target.style[`${key}`] = rules[`${key}`];
		});
	} else {
		Object.keys(rules).forEach((key) => {});
	}
}

export function getObserverEntries(
	...rawentries: (HTMLElement | null)[]
): HTMLElement[] {
	const entries: HTMLElement[] = [];
	rawentries.forEach((el) => {
		if (el) entries.push(el);
	});
	return entries;
}
