<template>
	<div id="center-for-parents-and-children" class="section-outer">
		<div class="section-inner">
			<img
				ref="dotRef"
				class="shadow-dot service-sd6"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<h3>{{ transl("app.services.section8.title") }}</h3>
			<p>{{ transl("app.services.section8.paragraph1") }}</p>
			<p>{{ transl("app.services.section8.paragraph2") }}</p>
			<p>{{ transl("app.services.section8.paragraph3") }}</p>
			<p>{{ transl("app.services.section8.paragraph4") }}</p>
			<p>{{ transl("app.services.section8.paragraph5") }}</p>
			<p>{{ transl("app.services.section8.paragraph6") }}</p>
			<div class="s8-content">
				<div class="left">
					<p>{{ transl("app.services.section8.paragraph7") }}</p>
					<p>{{ transl("app.services.section8.paragraph8") }}</p>
					<div
						class="session-pricing"
						:class="[{ foreign: activeLang === 'en' }]"
					>
						<p>{{ transl("app.services.section8.pricing.title") }}</p>
						<div class="session-box a">
							<span>
								{{ transl("app.services.section8.pricing.row1.zone") }}
							</span>
							<span>
								{{ transl("app.services.section8.pricing.row1.price") }}
							</span>
						</div>
						<div class="session-box b">
							<span>
								{{ transl("app.services.section8.pricing.row2.zone") }}
							</span>
							<span>
								{{ transl("app.services.section8.pricing.row2.price") }}
							</span>
						</div>
						<div class="session-box c">
							<span class="label">
								{{ transl("app.services.section8.pricing.row3.zone") }}
							</span>
							<span>
								{{ transl("app.services.section8.pricing.row3.price") }}
							</span>
						</div>
					</div>
					<p>{{ transl("app.services.section8.paragraph9") }}</p>
					<p>{{ transl("app.services.section8.paragraph10") }}</p>
					<p>{{ transl("app.services.section8.paragraph11") }}</p>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/photos/services/section8.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";
import { useDefaultStore } from "../../store/index";

let dotRef: Ref<HTMLElement | null> = ref(null);

const activeLang = computed(() => useDefaultStore().getBlogLanguage);
function observations() {
	handleAnimationObserver(getObserverEntries(dotRef.value), fadeRule);
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		margin-bottom: 150px;

		p {
			margin-bottom: 20px;
		}

		.s8-content {
			display: flex;
			justify-content: space-between;
			padding-right: 50px;

			.left {
				.session-pricing {
					margin-bottom: 20px;
					margin-top: 50px;

					&.foreign {
						.a,
						.b {
							display: none;
						}

						.c {
							.label {
								display: none;
							}

							span:not(.label) {
								margin: auto;
							}
						}
					}

					p {
						margin-top: 0;
					}
				}
			}

			.img-wrap {
				display: grid;
				place-items: center;

				img {
					max-height: 100%;
					max-width: 100%;
				}
			}
		}
	}
}

@media only screen and (width <= 1370px) {
	.section-outer {
		.section-inner {
			margin-bottom: 60px;

			h3 {
				margin: 0 0 30px;
			}

			.s8-content {
				flex-direction: column;
				padding-right: 10px;

				.img-wrap {
					width: 100%;
					margin: 30px auto;
					display: grid;
					place-items: center;

					img {
						width: 90%;
					}
				}
			}
		}
	}
}
</style>
