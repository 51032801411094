import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ServicesView from "@/views/ServicesView.vue";
import BlogView from "@/views/BlogView.vue";
import PageNotFound from "../views/PageNotFound.vue";
import { i18n } from "@/i18n";

const routes = [
	{
		path: "/",
		name: "Home",
		component: HomeView,
	},
	{
		path: "/services",
		name: "Services",
		component: ServicesView,
	},
	{
		path: "/blog",
		name: "Blog",
		component: BlogView,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "not-found",
		component: PageNotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	//@ts-ignore
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) return savedPosition;

		let hashEl: HTMLElement | null = null;
		if (to.hash) {
			hashEl = document.querySelector(to.hash);
		}
		if (hashEl) {
			return window.scrollTo({
				top: hashEl.offsetTop - 100,
				behavior: "smooth",
			});
		} else {
			if (from.name === to.name) {
				if (to.query.l && from.query.l !== to.query.l) {
					// If already changed lang and is different language
					// It should stay on the same scrollPos
					const currentScrollPos =
						document.documentElement.scrollTop || document.body.scrollTop;
					return { top: currentScrollPos };
				}
				return { top: 0, behavior: "smooth" };
			}
			return { top: 0 };
		}
	},
});

router.beforeEach((to, from, next) => {
	// eslint-disable-next-line no-storage/no-browser-storage
	const langStorage = localStorage.getItem("language");
	const langFromRoute = to.query.l;
	const lang = langFromRoute || langStorage || "sr";
	i18n.global.locale.value = lang;
	return next();
});

export default router;
