<template>
	<div id="tenis-one-to-one" class="section-outer">
		<div class="section-inner">
			<img
				ref="dotRef"
				class="shadow-dot service-sd2"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<h3>{{ transl("app.services.section3.title") }}</h3>
			<div class="top">
				<p>{{ transl("app.services.section3.upper.paragraph1") }}</p>
				<p>{{ transl("app.services.section3.upper.paragraph2") }}</p>
			</div>
			<div class="middle">
				<div class="m-content">
					<!-- FIRST START -->
					<p class="mc-title">
						{{ transl("app.services.section3.middle.content1.title") }}
					</p>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content1.paragraph1") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content1.paragraph2") }}
						</p>
					</div>
					<!-- FIRST END -->
					<!-- SECOND START  -->
					<p class="mc-title">
						{{ transl("app.services.section3.middle.content2.title") }}
					</p>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content2.paragraph1") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content2.paragraph2") }}
						</p>
					</div>

					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content2.paragraph3") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content2.paragraph4") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content2.paragraph5") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content2.paragraph6") }}
						</p>
					</div>
					<!-- SECOND END -->
					<!-- THIRD START -->
					<p class="mc-title">
						{{ transl("app.services.section3.middle.content3.title") }}
					</p>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content3.paragraph1") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content3.paragraph2") }}
						</p>
					</div>

					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content3.paragraph3") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content3.paragraph4") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content3.paragraph5") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content3.paragraph6") }}
						</p>
					</div>
					<div class="mc-grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section3.middle.content3.paragraph7") }}
						</p>
					</div>
					<!-- THIRD END -->
				</div>
				<div class="img-wrap">
					<img
						class="s3s-img"
						src="../../assets/images/photos/services/section3.png"
						alt=""
					/>
				</div>
			</div>
			<div class="bot">
				<p>{{ transl("app.services.section3.bottom.paragraph1") }}</p>
				<p>{{ transl("app.services.section3.bottom.paragraph2") }}</p>
				<p>{{ transl("app.services.section3.bottom.paragraph3") }}</p>
				<p>{{ transl("app.services.section3.bottom.paragraph4") }}</p>
				<p>{{ transl("app.services.section3.bottom.paragraph5") }}</p>
				<p>{{ transl("app.services.section3.bottom.paragraph6") }}</p>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";

let dotRef: Ref<HTMLElement | null> = ref(null);

function observations() {
	handleAnimationObserver(getObserverEntries(dotRef.value), fadeRule);
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		.middle {
			display: flex;
			margin-bottom: 20px;

			.m-content {
				flex-grow: 1;

				.mc-title {
					margin: 15px 0;
				}

				.mc-grp {
					display: flex;
					margin-bottom: 5px;

					img {
						margin: 3px 10px auto 0;
					}
				}
			}

			.img-wrap {
				min-height: 100%;

				.s3s-img {
					min-height: 100%;
				}
			}
		}

		.top,
		.bot {
			font-size: 16px;

			p {
				margin-bottom: 20px;
				font-weight: 400;
			}
		}
	}
}

@media only screen and (width <= 1100px) {
	.section-outer {
		.section-inner {
			.middle {
				.m-content {
					.mc-title {
						margin-top: 30px;
					}
				}

				.img-wrap {
					display: none;
				}
			}
		}
	}
}
</style>
