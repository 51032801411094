<template>
	<div class="section-outer">
		<div class="section-inner">
			<img
				ref="dotThreeRef"
				class="shadow-dot sd3"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<img
				id="s3-left-ball"
				ref="leftBallRef"
				src="../../assets/images/photos/home/s3-left-ball.svg"
				alt=""
			/>
			<img
				id="s3-right-ball"
				ref="rightBallRef"
				src="../../assets/images/photos/home/s3-right-ball.svg"
				alt=""
			/>
			<!-- @click="toggleVideo" -->
			<div class="s3-video-wrap">
				<!-- <video
          ref="videoRef"
          class="s3-video"
          :src="computedVideoSrc"
          @play="handleVideoPlay"
          @pause="handleVideoPause"
        ></video> -->
				<iframe
					v-if="i18nLocal === 'en'"
					width="560"
					height="315"
					src="https://www.youtube.com/embed/VCwH2OaJ3LI"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
				<iframe
					v-if="i18nLocal === 'sr'"
					width="560"
					height="315"
					src="https://www.youtube.com/embed/hpELmBe9d38"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
				<!-- <div v-if="!isVideoPlaying" class="play-button">
          <i class="fas fa-play"></i>
        </div> -->
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import {
	fadeRule,
	type ObserverRule,
} from "@/assets/js/observerAnimations/rules";
// import { watch } from "vue";
// import videoEnPath from "../../../public/ljubicaeng.mp4";
// import videoSrbPath from "../../../public/ljubicasrb.mp4";
import { i18n } from "@/i18n";

// let computedVideoSrc: Ref<string> = ref("");

let i18nLocal = computed<string>(() => {
	return i18n.global.locale.value;
});

// watch(
//   () => i18nLocal.value,
//   (newLocale) => {
//     const videoFileName = newLocale === "en" ? videoEnPath : videoSrbPath;
//     computedVideoSrc.value = videoFileName;
//   }
// );

// function initialVideoSet() {
//   computedVideoSrc.value =
//     i18nLocal.value === "en" ? videoEnPath : videoSrbPath;
// }
let dotThreeRef: Ref<HTMLElement | null> = ref(null);
let rightBallRef: Ref<HTMLElement | null> = ref(null);
let leftBallRef: Ref<HTMLElement | null> = ref(null);

// let videoRef: Ref<HTMLVideoElement | null> = ref(null);
// let isVideoPlaying: Ref<boolean> = ref(false);

// function toggleVideo() {
//   const video = videoRef.value;
//   if (video) {
//     if (isVideoPlaying.value) {
//       video.pause();
//     } else {
//       video.play();
//     }
//     isVideoPlaying.value = !isVideoPlaying.value;
//   }
// }

// function handleVideoPlay() {
//   isVideoPlaying.value = true;
// }

// function handleVideoPause() {
//   isVideoPlaying.value = false;
// }

const rightBallRule: ObserverRule = {
	hidden: {
		opacity: "0",
		transitionDuration: ".7s",
		transform: "rotate(90deg) scale(0) translateY(-42px)",
	},
	visible: {
		opacity: "1",
		transform: "rotate(0deg) scale(1) translateY(0px)",
	},
};

const leftBallRule: ObserverRule = {
	hidden: {
		opacity: "0",
		transitionDuration: ".7s",
		transitionDelay: ".5s",
		transform: "rotate(45deg) scale(0.1) translateX(-242px)",
	},
	visible: {
		opacity: "1",
		transform: "rotate(0deg) scale(1) translateX(0px)",
	},
};

function observations() {
	handleAnimationObserver(getObserverEntries(dotThreeRef.value), fadeRule);
	handleAnimationObserver(
		getObserverEntries(rightBallRef.value),
		rightBallRule,
		{ root: null, rootMargin: "-50px 0px -70px", threshold: 0.5 },
	);
	handleAnimationObserver(getObserverEntries(leftBallRef.value), leftBallRule, {
		root: null,
		rootMargin: "-50px 0px -70px",
		threshold: 0.5,
	});
}

onMounted(() => {
	observations();
	// initialVideoSet();
});
</script>

<style scoped lang="scss">
.section-outer {
	.section-inner {
		padding: 100px 0;

		#s3-left-ball {
			position: absolute;
			left: -100px;
			bottom: 0;
			width: 10vw;
			max-width: 150px;
			min-width: 50px;
		}

		#s3-right-ball {
			position: absolute;
			right: -100px;
			top: 0;
			width: 10vw;
			max-width: 120px;
		}

		.s3-video-wrap {
			width: 100%;
			display: flex;
			justify-content: center;
			position: relative;
			cursor: pointer;

			.s3-video {
				display: block;
				max-width: 90%;
				border-radius: 20px;
			}

			.play-button {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 40px;
				color: white;
				cursor: pointer;

				i {
					transition: transform 0.2s;
				}

				&:hover i {
					transform: scale(1.1);
				}
			}
		}
	}
}

@media only screen and (width <= 1700px) {
	.section-outer {
		.section-inner {
			#s3-left-ball {
				left: 0;
				bottom: 0;
			}

			#s3-right-ball {
				right: 0;
				top: 0;
			}
		}
	}
}

@media only screen and (width <= 500px) {
	.section-outer {
		margin-top: 30px;

		.section-inner {
			padding: 70px 0 50px;
		}
	}
}
</style>
