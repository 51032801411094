<template>
	<Transition name="slide-left">
		<SideBar
			v-if="isSidebarActive"
			@toogle-sidebar="toogleSidebar"
			@wheel.prevent
			@touchmove.prevent
			@scroll.prevent
		/>
	</Transition>
	<HeaderComp @toogle-sidebar="toogleSidebar" />
	<RouterView />
	<FooterComp />
	<div v-if="canShowScrollTop" class="wrapImgTop" @click="onMoveToTop">
		<div class="img">
			<svg
				width="16"
				height="11"
				viewBox="0 0 16 11"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M2 9L8 3L14 9" stroke="white" stroke-width="4"></path>
			</svg>
		</div>
	</div>
</template>

<script setup lang="ts">
import { scrollToTop } from "./assets/js/helpers";
import { useDefaultStore } from "./store";

const isSidebarActive = ref(false);
const canShowScrollTop = ref(false);

const toogleSidebar = () => (isSidebarActive.value = !isSidebarActive.value);

window.onscroll = function () {
	const offset = window.innerHeight / 2;
	if (
		document.body.scrollTop > offset ||
		document.documentElement.scrollTop > offset
	) {
		canShowScrollTop.value = true;
	} else {
		canShowScrollTop.value = false;
	}
};

function onMoveToTop() {
	// document.body.scrollTop = 0;
	// document.documentElement.scrollTop = 0;

	scrollToTop();
}

onMounted(() => {
	useDefaultStore().getConfig();
});
</script>

<style lang="scss">
@import "https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800&display=swap";
@import "./assets/css/base.css";

// html {
//   scroll-behavior: smooth;
// }

#app {
	.wrapImgTop {
		--main-background-color: #061325;
		--btn-color: #f7fb51;
		--text-color: #fff;

		font-family: Archivo, sans-serif;
		color: var(--text-color);
		position: fixed;
		bottom: 40px;
		right: 40px;
		box-shadow: 0 0 0 0 var(--btn-color);
		transform: scale(1);
		animation: pulse-data 2s infinite;
		height: 42px;
		width: 42px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--btn-color);

		@media screen and (width > 768px) {
			bottom: 80px;
			right: 50px;
		}

		.img {
			color: var(--text-color);
			height: 42px;
			width: 42px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: var(--btn-color);
			cursor: pointer;
			z-index: 1001;

			path {
				color: var(--text-color);
				cursor: pointer;
				stroke-width: 4;
				stroke: var(--main-background-color);
			}
		}

		@keyframes pulse-data {
			0% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 var(--btn-color);
			}

			70% {
				transform: scale(1);
				box-shadow: 0 0 0 10px transparent;
			}

			100% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 transparent;
			}
		}
	}
}
</style>
