<template>
	<div id="workshop-archieve-your-goal" class="section-outer">
		<div class="section-inner">
			<img
				ref="dotRef"
				class="shadow-dot service-sd5"
				src="../../assets/images/dots/desktop/dot1.svg"
				alt=""
			/>
			<h3>{{ transl("app.services.section7.title") }}</h3>
			<p>{{ transl("app.services.section7.paragraph1") }}</p>
			<p>{{ transl("app.services.section7.paragraph2") }}</p>
			<p>{{ transl("app.services.section7.paragraph3") }}</p>
			<p>{{ transl("app.services.section7.paragraph4") }}</p>
			<div class="grp">
				<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
				<p>
					{{ transl("app.services.section7.paragraph5") }}
				</p>
			</div>
			<div class="grp">
				<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
				<p>
					{{ transl("app.services.section7.paragraph6") }}
				</p>
			</div>
			<div class="grp">
				<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
				<p>
					{{ transl("app.services.section7.paragraph7") }}
				</p>
			</div>
			<div class="grp">
				<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
				<p>
					{{ transl("app.services.section7.paragraph8") }}
				</p>
			</div>
			<div class="grp">
				<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
				<p>
					{{ transl("app.services.section7.paragraph9") }}
				</p>
			</div>
			<div class="grp">
				<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
				<p>
					{{ transl("app.services.section7.paragraph10") }}
				</p>
			</div>
			<p class="mt20">{{ transl("app.services.section7.paragraph11") }}</p>
			<p>{{ transl("app.services.section7.paragraph12") }}</p>
			<p>{{ transl("app.services.section7.paragraph13") }}</p>
			<p>{{ transl("app.services.section7.paragraph14") }}</p>
			<div class="bottom">
				<div class="content">
					<p>{{ transl("app.services.section7.bottom.paragraph1") }}</p>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section7.bottom.paragraph2") }}
						</p>
					</div>
					<div class="grp">
						<img src="../../assets/images/icons/ball-yellow.svg" alt="" />
						<p>
							{{ transl("app.services.section7.bottom.paragraph3") }}
						</p>
					</div>
					<p class="mt20">
						{{ transl("app.services.section7.bottom.paragraph4") }}
					</p>
					<div
						class="session-pricing"
						:class="[{ foreign: activeLang === 'en' }]"
					>
						<p>{{ transl("app.services.section7.bottom.pricing.title") }}</p>
						<div class="session-box a">
							<span>{{
								transl("app.services.section7.bottom.pricing.row1.zone")
							}}</span>
							<span>{{
								transl("app.services.section7.bottom.pricing.row1.price")
							}}</span>
						</div>
						<div class="session-box b">
							<span>{{
								transl("app.services.section7.bottom.pricing.row2.zone")
							}}</span>
							<span>{{
								transl("app.services.section7.bottom.pricing.row2.price")
							}}</span>
						</div>
						<div class="session-box c">
							<span class="label">{{
								transl("app.services.section7.bottom.pricing.row3.zone")
							}}</span>
							<span>{{
								transl("app.services.section7.bottom.pricing.row3.price")
							}}</span>
						</div>
					</div>
					<p class="mt20">
						{{ transl("app.services.section7.bottom.paragraph15") }}
					</p>
					<p>
						{{ transl("app.services.section7.bottom.paragraph16") }}
					</p>
				</div>
				<div class="img-wrap">
					<img src="../../assets/images/photos/services/section7.png" alt="" />
				</div>
			</div>
			<div class="img-wrap-mob">
				<img src="../../assets/images/photos/services/section7.png" alt="" />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { fadeRule } from "@/assets/js/observerAnimations/rules";
import { useDefaultStore } from "@/store";

let dotRef: Ref<HTMLElement | null> = ref(null);

const activeLang = computed(() => useDefaultStore().getBlogLanguage);
function observations() {
	handleAnimationObserver(getObserverEntries(dotRef.value), fadeRule);
}

onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	.section-inner {
		padding-bottom: 150px;

		h3 {
			margin: 150px 0 50px;
		}

		p {
			margin-bottom: 20px;
		}

		.img-wrap-mob {
			display: none;
		}

		.grp {
			display: flex;
			margin-bottom: 5px;

			p {
				margin-bottom: 0;
			}

			img {
				margin: 3px 10px auto 0;
			}
		}

		.bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.img-wrap {
				display: grid;
				place-items: center;
				margin-left: 50px;
			}

			.content {
				max-width: 600px;

				.session-pricing {
					&.foreign {
						.a,
						.b {
							display: none;
						}

						.c {
							.label {
								display: none;
							}

							span:not(.label) {
								margin: auto;
							}
						}
					}

					p {
						margin-top: 40px;
					}
				}
			}
		}
	}
}

@media only screen and (width <= 1200px) {
	.section-outer {
		.section-inner {
			padding-bottom: 60px;

			h3 {
				margin: 60px 0 30px;
				font-size: 32px;
			}

			.img-wrap-mob {
				display: grid;
				place-items: center;

				img {
					width: 100%;
				}
			}

			.bottom {
				.img-wrap {
					display: none !important;
				}
			}
		}
	}
}
</style>
