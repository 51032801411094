<template>
	<div class="section-outer">
		<div class="section-inner">
			<h1 ref="h1Ref">{{ transl("app.home.section1.header") }}</h1>
			<h5 ref="h5Ref">{{ transl("app.home.section1.subHeader") }}</h5>
		</div>
	</div>
</template>
<script setup lang="ts">
import { transl } from "@/i18n";
import {
	handleAnimationObserver,
	getObserverEntries,
} from "../../assets/js/observerAnimations/animationObserver";
import { type ObserverRule } from "@/assets/js/observerAnimations/rules";

let h1Ref: Ref<HTMLElement | null> = ref(null);
let h5Ref: Ref<HTMLElement | null> = ref(null);

const h1Rule: ObserverRule = {
	hidden: {
		opacity: "0",
		transitionDuration: ".5s",
	},
	visible: {
		opacity: "1",
		transitionDuration: "2s",
	},
};
const h5Rule: ObserverRule = {
	hidden: {
		opacity: "0",
		transitionDuration: ".5s",
		transitionDelay: "1s",
	},
	visible: {
		opacity: "1",
		transitionDuration: "2s",
		transitionDelay: "1s",
	},
};
function observations() {
	handleAnimationObserver(getObserverEntries(h1Ref.value), h1Rule);
	handleAnimationObserver(getObserverEntries(h5Ref.value), h5Rule);
}
onMounted(() => {
	observations();
});
</script>
<style scoped lang="scss">
.section-outer {
	text-align: center;
	height: 900px;
	max-height: 90vh;
	display: flex;
	background-image: url("../../assets/images/photos/home/sectionOneCover.png");
	background-position: center;
	background-size: cover;

	.section-inner {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		h1 {
			font-size: 3rem;
			margin-bottom: 0;
			opacity: 0;
		}

		h5 {
			color: $color-yellow;
			font-size: 1.8rem;
			font-weight: 400;
			margin-bottom: 30px;
			font-style: italic;
			opacity: 0;
		}
	}
}

@media only screen and (width <= 600px) {
	.section-outer .section-inner {
		h1 {
			font-size: 2rem;
		}

		h5 {
			font-size: 1rem;
			margin-bottom: 80px;
		}
	}
}
</style>
